import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { APIContext } from '@monash/portal-frontend-common';
import {
  DropdownWithValue,
  InlineExternalLink,
  SearchBox,
} from '@monash/portal-react';
import ExpandableCard from '../../common/expandable-card/ExpandableCard';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import UpdateForm from './update-form/UpdateForm';
import { byOrGroup, bySearchTerm, byStatus } from './utils';
import { STATUS_FILTERS } from './constants';
import c from './updates.module.scss';
import cs from '../../common/filter-dropdown/filter-dropdown.module.scss';
import {
  modifyCodesForCitizenship,
  removeCodeSuffix,
} from '../../common/user-groups-selector/utils';
import { formatDateRange } from '../../utilities/format-date';
import BlockErrorMessage from '../../common/block-error-message/BlockErrorMessage';
import NewFormButton from '../../common/new-form-button/NewFormButton';
import { NO_FILTER_SELECTED_TEXT } from '../../../constants/text';

const Updates = () => {
  const [searchTarget, setSearchTarget] = useState('');
  const [orGroupFilter, setOrGroupFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [updatesList, setUpdatesList] = useState([]);
  const [updatesError, setUpdatesError] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsError, setUserGroupsError] = useState(null);

  const { slide, setSlide, open, setOpen } = useContext(SlideOutContext);
  const { getUserGroups, getUpdates } = useContext(APIContext);

  const userGroupDropdownList = [
    {
      text: NO_FILTER_SELECTED_TEXT,
      value: null,
    },
    ...modifyCodesForCitizenship(userGroups)?.map((item) => {
      return {
        text: `${removeCodeSuffix(item.code)} - ${item.name}`,
        value: item.code,
      };
    }),
  ];

  useEffect(() => {
    getUserGroups('updates')
      .then((response) => {
        setUserGroups(response?.student);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setUserGroupsError(true);
      });

    getUpdates()
      .then((response) => {
        setUpdatesList(response);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setUpdatesError(true);
      });
  }, []);

  const openSlideForm = (update) => {
    setSlide({
      content: (
        <UpdateForm
          existingUpdate={update}
          userGroups={userGroups}
          setUpdatesList={setUpdatesList}
          getUpdates={getUpdates}
          key={update ? JSON.stringify(update) : Math.random()}
        />
      ),
      data: update,
    });
    setOpen(true);
  };

  const renderContent = () => {
    // error => render "something went wrong"
    if (updatesError || userGroupsError) {
      return <BlockErrorMessage />;
    }

    // no error => render regular content
    return (
      <>
        <div className={c.searchWrapper}>
          <SearchBox searchString={searchTarget} onInput={setSearchTarget} />
        </div>

        <div className={c.filtersWrapper}>
          <DropdownWithValue
            id="orGroupFilter"
            placeholder="User group"
            ariaLabelledby="orGroup"
            onChange={setOrGroupFilter}
            value={orGroupFilter}
            data={userGroupDropdownList}
            className={cs.customDropdown}
          />
          <DropdownWithValue
            id="status"
            placeholder="Status"
            ariaLabelledby="status"
            onChange={setStatusFilter}
            value={statusFilter}
            data={STATUS_FILTERS}
            className={cs.customDropdown}
          />
        </div>

        <div className={c.cardsWrapper}>
          {updatesList
            .filter(bySearchTerm(searchTarget))
            .filter(byOrGroup(orGroupFilter))
            .filter(byStatus(statusFilter))
            .sort((a, b) => {
              const latestSaveA = a.history[a.history.length - 1]?.time;
              const latestSaveB = b.history[b.history.length - 1]?.time;
              if (latestSaveA > latestSaveB) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((update) => {
              const { id, description, startDate, endDate, ...restUpdate } =
                update;
              const label = formatDateRange(startDate, endDate);
              return (
                <ExpandableCard
                  onClick={() => {
                    openSlideForm(update);
                  }}
                  expandedContent={
                    <>
                      {description}

                      {update.links?.length ? (
                        <div className={c.links}>
                          {update.links.map(({ label, url }) => {
                            const textLabel = label || url;
                            return (
                              <InlineExternalLink
                                link={url}
                                text={textLabel}
                                className={c.link}
                                key={label}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                    </>
                  }
                  label={label}
                  selected={open && slide?.data?.id === id}
                  {...restUpdate}
                  key={id}
                />
              );
            })}
        </div>
      </>
    );
  };

  return (
    <div className={c.container}>
      <div className={c.header}>
        <div className={c.headingGroup}>
          <h1>Updates</h1>
        </div>
        <NewFormButton
          isMinimised={open}
          onClick={() => {
            openSlideForm();
          }}
          aria-label="Add update"
          disabled={updatesError || userGroupsError}
        >
          Add update
        </NewFormButton>
      </div>

      {renderContent()}
    </div>
  );
};

export default Updates;
